<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height">
              <b-col cols="12">
                <tab-position :tabs="tabs" :name="'Plot'" />
              </b-col>
            </b-row> -->
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/plot`"
                title="Plot"
                :columns="columns"
                routerpath="/master/crm/addplotmaster"
                :formOptions="formOptions"
                :addPlot="true"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
                viewComponent="PlotShow"
              >
              <template #customField='{props}'>

                  <span v-if="props.column.field === 'plotsize'">
                        {{ props.row.plotno ? (props.row.plotno.plotareayd) : '' }}
                        </span>
                        <span v-else-if="props.column.field === 'plotrate'">
                                {{ props.row.plotno.length > 0 ?
                                (props.row.team_data.length>0 &&props.row.team_data[0].role == 'franchise' ? props.row.plotno.franchiserate : props.row.plotno.rateperyd )
                                : '' }}
                        </span>
                        <span v-else-if="props.column.field === 'plotvalue'">
                              {{ props.row.plotno.length > 0 ?
                                (props.row.team_data.length>0 &&props.row.team_data[0].role == 'franchise' ? props.row.plotno.plotValueFr : props.row.plotno.plotValue )
                                : '' }}
                        </span>
                        <span v-else-if="props.column.field === 'receivedamount'">
                              {{ props.row.plotno.length > 0 ? (parseFloat(props.row.totalpayment? props.row.totalpayment: 0 ) - parseFloat(props.row.remainingpayment?props.row.remainingpayment:0)).toFixed(2) : '' }}
                        </span>
                         <span v-else-if="props.column.field === 'customer' " class="cursor-pointer">
                          <div v-if="props.row.sales_data && props.row.sales_data.customer">
                            {{ props.row.sales_data.customer.fullname }}
                          </div>
                      </span>
              </template>

              </good-table-column-search>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
import TabPosition from "../../../../components/TabView.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
    TabPosition,
  },
  data() {
    return {
      moment,
      // tabs: [
      //   { name: 'Lead Source', route: '/master/crm/leadsource', icon: 'CompassIcon' },
      //   { name: 'Lead Stage', route: '/master/crm/leadstage', icon: 'CommandIcon' },
      //   { name: 'Campaign Name', route: '/master/crm/campaign', icon: 'MinimizeIcon' },
      //   { name: 'Reason', route: '/master/crm/reason', icon: 'ApertureIcon' },
      //   { name: 'Channel Partner', route: '/master/crm/channelpartner', icon: 'UsersIcon' },
      //   { name: 'Franchise', route: '/master/crm/franchise', icon: 'FramerIcon' },
      //   { name: 'Project', route: '/master/crm/project', icon: 'PackageIcon' },
      //   { name: 'Plot', route: '/master/crm/plot', icon: 'MapIcon' },
      //   { name: 'Land', route: '/master/crm/land', icon: 'SlackIcon' },
      //   { name: 'Commission', route: '/master/crm/commission', icon: 'GitCommitIcon' },
      //   { name: 'Project Mapping', route: '/master/crm/projectmapping', icon: 'MapPinIcon' }
      // ],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      paging: "pagenum=1&pagesize=50",
      formOptions: {
        formTitle: `${
          this.$route.params.id ? "Edit Plot Master" : "Add Plot Master"
        }`,
        // submitButtonName: "Add Plot Master",
        submitRouterPath: "/master/crm/plotmaster",
        gridForm: true,
        method: "post",
        action: "add",
        url: `${baseApi}/plot`,
        inputFields: [
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          {
            label: "Plot No",
            name: "plotno",
            value: "",
            type: "text",
            placeholder: "Enter Plot No",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Plot Name/Customer Name",
            name: "plotname",
            value: "",
            type: "text",
            placeholder: "Enter Name",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Plot No",
            name: "plotno",
            value: "",
            type: "text",
            placeholder: "Enter Plot No",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Plot Area Yard",
            name: "plotareayard",
            value: "",
            type: "text",
            placeholder: "Enter Plot Area Yard",
            class: "col-md-6",
            required: true,
          },
          {
            label: ".Rate Per(Sq. Yd)",
            name: "ratepersqyd",
            value: "",
            type: "text",
            placeholder: "Enter .Rate Per(Sq. Yd)",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Plot value",
            name: "plotvalue",
            value: "",
            type: "number",
            placeholder: "Enter Plot value",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Sq meters",
            name: "sqmeters",
            value: "",
            type: "number",
            placeholder: "Enter Sq meters",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Plot Area(Sq. Mtr) Carpet",
            name: "plotarea",
            value: "",
            type: "number",
            placeholder: "Enter Plot Area(Sq. Mtr) Carpet",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Sq. Feet",
            name: "sqfeet",
            value: "",
            type: "number",
            placeholder: "Enter Sq. Feet",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Project",
            name: "project",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            responseValue: "project",
            url: "project",
            required: true,
            responseValue: "id",
            options: [],
          },
          {
            label: "New Survey No",
            name: "newsurveyno",
            value: "",
            type: "number",
            placeholder: "Enter New Survey No",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Old Survey No",
            name: "oldsurveyno",
            value: "",
            type: "number",
            placeholder: "Enter Old Survey No",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Status",
            name: "status",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            responseValue: "status",
            required: true,
            responseValue: "id",
            options: [{ name: "Vacant" }, { name: "Booked" }, { name: "Sold" }],
          },
          {
            label: "Towards the North",
            name: "towardsthenorth",
            value: "",
            type: "text",
            placeholder: "Enter Towards the North",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Towards the South",
            name: "towardsthesouth",
            value: "",
            type: "text",
            placeholder: "Enter Towards the South",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Towards the East",
            name: "towardstheeast",
            value: "",
            type: "text",
            placeholder: "Enter Towards the East",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Towards the West",
            name: "towardsthewest",
            value: "",
            type: "text",
            placeholder: "Enter Towards the West",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Registration No",
            name: "registrationno",
            value: "",
            type: "text",
            placeholder: "Enter Registration No",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Registration Date",
            name: "registrationdate",
            value: new Date(),
            type: "datetime",
            placeholder: "Enter Registration date",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Na Order No",
            name: "naorderno",
            value: "",
            type: "number",
            placeholder: "Enter Na Order No",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Address",
            name: "address",
            value: "",
            type: "text",
            placeholder: "Enter Address",
            class: "col-md-6",
            required: true,
          },
          {
            label: "City",
            name: "city",
            value: "",
            type: "text",
            placeholder: "Enter city",
            class: "col-md-6",
            required: true,
          },
          {
            label: "State",
            name: "state",
            value: "",
            type: "text",
            placeholder: "Enter state",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Country",
            name: "country",
            value: "",
            type: "text",
            placeholder: "Enter Country",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Pin Code",

            name: "pincode",
            value: "",
            type: "number",
            placeholder: "Enter Pincode",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Document",
            name: "document",
            value: "",
            type: "fileinput",
            placeholder: "select Document",
            class: "col-md-6",
            required: true,
          },
        ],
      },
      columns: [
        {
          label: "Plot ID",
          field: "id",
          filterOptions: {
            enabled: true,
            placeholder: "Search Plot ID",
          },
          hidden: true,
        },
        {
          label: "Plot No",
          field: "plotno",
          filterOptions: {
            enabled: true,
            placeholder: "Search Plot No",
          },
        },
        {
          label: "Project",
          field: "project",
          searchField:'project_id',
          responseValue:'projectname',
          type: "dropdown",
          url: "getProject",
          filterOptions: {
            enabled: true,
            placeholder: "Search Project",
          },
        },
        {
          label: "Assignto",
          field: "assigntoRole",
          filterOptions: {
            enabled: true,
            placeholder: "Search Area",
          },
        },
        {
          label: "Plot Area In Sq.Feet",
          field: "plotareasqfeet",
          filterOptions: {
            enabled: true,
            placeholder: "Search Area",
          },
        },
        {
          label: "Plot Value",
          field: "plotValue",
          filterOptions: {
            enabled: true,
            placeholder: "Search Value",
          },
        },

        {
          label: "Plot Amount",
          field: "plotamount",
          filterOptions: {
            enabled: true,
            placeholder: "Search Amount",
          },
        },
        {
          label: "Extra Amount",
          field: "extraamount",
          filterOptions: {
            enabled: true,
            placeholder: "Search Amount",
          },
        },

        {
          label: "Status",
          field: "status",
          filterOptions: {
            enabled: true,
            placeholder: "Search Status",
          },
        },
         {
          label: "Remarks",
          field: "remarks",
          filterOptions: {
            enabled: true,
            placeholder: "Search Remarks",
          },
        },
        {
          label: "Customer Name",
          field: "customer",
          filterOptions: {
            enabled: true,
            placeholder: "Search Customer",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          responseValue:'name',
          type: "staticdropdown",
          url:'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "created_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          responseValue:'name',
          type: "staticdropdown",
          url:'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "updated_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },

        {
          label: "Action",
          field: "action",
        },
      ],
      edit: "",
      add: "",
      canDelete: "",
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Plot") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

